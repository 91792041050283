import React, {lazy} from "react";

const LayoutAdmin = lazy(() => import("./../layouts/admin"));


const CompanyPage = lazy(() => import("./../pages/settings/companyPage"));
const LocationPage = lazy(() => import("./../pages/settings/locationPage"));
const DeparmentPage = lazy(() => import("./../pages/settings/deparmentPage"));
const EmployeePage = lazy(() => import("./../pages/settings/employeePage"));
const MotivePage = lazy(() => import("./../pages/settings/motivePage"));
const UserPage = lazy(() => import("./../pages/settings/userPage"));

const VisitPage = lazy(() => import("./../pages/reports/VisitPage"));


export const OptionMenu = [

    {
        inx: 1,
        name: "Configuraciones",
        path: "app",
        role: [1,3],
        Component: <LayoutAdmin/>,
        children: [
            {
                inx: 2,
                role: [1],
                name: "Compañías",
                path: "companies",
                icon: "fa fa-building",
                Component: <CompanyPage/>
            },
            {
                inx: 3,
                role: [1],
                name: "Localidades",
                path: "locations",
                icon: "fa fa-location-arrow",
                Component: <LocationPage/>
            },
            {
                inx: 4,
                role: [1],
                name: "Departamentos",
                path: "departments",
                icon: "fa fa-users",
                Component: <DeparmentPage/>
            },
            {
                inx: 5,
                role: [1],
                name: "Empleados",
                path: "employees",
                icon: "fa fa-id-badge",
                Component: <EmployeePage/>
            },
            {
                inx: 6,
                role: [1,3],
                name: "Motivos",
                path: "motives",
                icon: "fa fa-list",
                Component: <MotivePage/>
            },
            {
                inx: 7,
                role: [1],
                name: "Usuarios",
                path: "users",
                icon: "fa fa-users",
                Component: <UserPage/>
            }
        ]
    },
    {
        inx: 8,
        name: "Reportes",
        path: "app",
        role: [1,3],
        Component: <LayoutAdmin/>,
        children: [
            {
                inx: 9,
                role: [1,3],
                name: "Visitas",
                path: "reports/visits",
                icon: "fa fa-calendar",
                Component: <VisitPage/>
            }
        ]
    }


]

