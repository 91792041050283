import './assets/style/App.css';
import React, {lazy} from "react"

import {BrowserRouter,  Route, Routes} from "react-router-dom";

import {GetAccount} from "helpers/functions";

import "./assets/scss/style.scss";
import {OptionMenu} from "./models/options";

const PageNotFound = lazy(() => import("./components/share/error/404"));
const LoginPage = lazy(() => import("./pages/loginPage"));

function NotFoundPage() {
  return  <PageNotFound url="/"/>
}

const HomePage = lazy(() => import("./pages/homePage"));
const LayoutAdmin = lazy(() => import("./layouts/admin"));

const account = GetAccount();

function App() {

  return <BrowserRouter>
    <Routes>
      {OptionMenu.filter((i) => i.role.includes(account.userRole)).map((g) => (
          <Route path={g.path} element={g.Component} key={g.inx}>
            {g.children.filter((i) => i.role.includes(account.userRole)).map((opt) => (
              <Route path={opt.path} element={opt.Component} key={opt.inx} />
            ))}
          </Route>
        ))}
      <Route path="app" element={<LayoutAdmin/>}>
        <Route path="" element={<HomePage />}/>
      </Route>
      <Route path="/" element={<LoginPage/>}/>
      <Route path='*' element={<NotFoundPage/>}/>
    </Routes>
  </BrowserRouter>

}


/*
<Route path="app" element={<LayoutAdmin/>}>
      <Route path="" element={<ProtectedRoute><HomePage /></ProtectedRoute>}/>
      <Route path="companies" element={<CompanyPage />}/>
      <Route path="locations" element={<ProtectedRoute><LocationPage /></ProtectedRoute>}/>
      <Route path="departments" element={<ProtectedRoute><DeparmentPage /></ProtectedRoute>}/>
      <Route path="employees" element={<ProtectedRoute><EmployeePage/></ProtectedRoute>}/>
      <Route path="motives" element={<ProtectedRoute><MotivePage/></ProtectedRoute>}/>
      <Route path="users" element={<ProtectedRoute><UserPage/></ProtectedRoute>}/>
      <Route path="reports/visits" element={<ProtectedRoute><VisitPage/></ProtectedRoute>}/>
</Route>
*/

export default App;
